import { Provider } from 'wikr-core-analytics';
import { call, takeLatest, all, put } from 'redux-saga/effects';

import { removeToken, removeWebToken, setToken, setWebToken, setRefreshToken } from 'api/utils/tokenManagement';
import { GeneralResponse } from 'api/types/response';
import api from 'api';

import { resetState } from 'store/rootActions';

import {
    signInRequest,
    signInError,
    authentication,
    setAuthenticationStatus,
    authenticationBySignature,
    setAuthRedirectUrl,
    resetAuth,
} from './actions';
import { getCurrentUserSuccess } from '../user/actions';
import { notifyError } from '../notifications/actions';

import { SIGN_IN_REQUEST, AUTHENTICATE, AUTHENTICATE_BY_SIGNATURE, LOG_OUT, WEB_TOKEN_REQUEST } from './actionTypes';

import { DEFAULT_UNSUBSCRIBE_PLATFORM } from 'constants/subscriptions';
import { AUTH_ONE_TIME_TOKEN_NAME, AUTH_REFRESH_TOKEN_NAME } from 'constants/api';

import { clearUserDataFromAnalytics } from 'services/analytics';

import { getParamFromUrl } from 'helpers/utils';

import { WebTokenData } from 'types/user/userApiInterface';
import { UserStore } from 'types/store/userStore';

function* signIn({ payload }: ReturnType<typeof signInRequest>) {
    const { userData, onSuccess, onError } = payload;

    try {
        const response: GeneralResponse<UserStore> = yield call(api.user.signIn, userData);

        setToken(response.headers.access_token);

        const responseWebToken: GeneralResponse<WebTokenData> = yield call(api.user.getWebToken);
        const userDataResponse = response.data;

        setWebToken(responseWebToken.data.ott);
        setRefreshToken(response.headers.refresh_token);

        yield put(getCurrentUserSuccess(userDataResponse));

        userDataResponse?.country &&
            Provider.provideData({
                country: async () => await userDataResponse.country,
                abTestName: async () => await null,
            });

        userDataResponse?.id && Provider.setUserId(userDataResponse.id);

        yield put(setAuthenticationStatus(true));

        onSuccess && onSuccess(userDataResponse);
    } catch (error: any) {
        yield put(notifyError('message.error.somethingWentWrong'));

        if (error?.data?.message || error?.data?.error) yield put(signInError(error?.message || error.error));

        onError && onError();
    }
}

function* authenticate({ payload }: ReturnType<typeof authentication>) {
    const { onSuccess, onError, params } = payload;

    try {
        let userData;

        if (params?.has(AUTH_ONE_TIME_TOKEN_NAME)) {
            const responseData: GeneralResponse<UserStore> = yield call(api.user.tokenSignIn, {
                web_token: params?.get(AUTH_ONE_TIME_TOKEN_NAME),
                idfa: null,
                appsflyer_id: null,
                firebase_id: null,
                push_token: null,
            });

            userData = responseData.data;
            const refreshToken = responseData.headers.refresh_token;
            const token = responseData.headers.access_token;

            setToken(token);
            setRefreshToken(refreshToken);
        } else if (params?.has(AUTH_REFRESH_TOKEN_NAME)) {
            const response: { token: 'string'; refresh_token: 'string'; userData: UserStore } = yield call(
                api.user.generateToken,
                { refresh_token: params?.get(AUTH_REFRESH_TOKEN_NAME) }
            );

            userData = response.userData;
        } else {
            const response: GeneralResponse<UserStore> = yield call(api.user.getUser);

            userData = response.data;
        }
        yield put(getCurrentUserSuccess(userData));
        yield put(setAuthenticationStatus(true));

        userData?.id && Provider.setUserId(userData.id);

        onSuccess && onSuccess();

        yield put(setAuthRedirectUrl(null));
    } catch (error: any) {
        yield put(notifyError('message.error.somethingWentWrong'));
        yield put(setAuthenticationStatus(false));

        onError && onError();
    }
}

function* authenticateBySignature({ payload }: ReturnType<typeof authenticationBySignature>) {
    const { uId, signature, onSuccess, onError } = payload;
    const utmSource = getParamFromUrl('utm_source') || DEFAULT_UNSUBSCRIBE_PLATFORM;

    localStorage.setItem('utmSource', utmSource);
    Provider.setUTMSource(utmSource);

    try {
        const userData: UserStore = yield call(api.user.getUserBySignature, { uId, signature });

        yield put(getCurrentUserSuccess(userData));

        userData?.id && Provider.setUserId(userData.id);
        userData?.token && setToken(userData.token);

        yield put(setAuthenticationStatus(true));

        onSuccess && onSuccess();

        yield put(setAuthRedirectUrl(null));
    } catch (error: any) {
        yield put(notifyError('message.error.somethingWentWrong'));
        yield put(setAuthenticationStatus(false));

        onError && onError();
    }
}

function* getWebToken({ payload }: ReturnType<typeof signInRequest>) {
    // const { onSuccess, onError } = payload;

    try {
        const response: GeneralResponse<WebTokenData> = yield call(api.user.getWebToken);

        setWebToken(response.data.ott);

        // onSuccess && onSuccess();
    } catch (error: any) {
        yield put(notifyError('message.error.somethingWentWrong'));

        if (error?.message || error?.error) yield put(signInError(error?.message || error.error));

        // onError && onError();
    }
}

function* logout() {
    clearUserDataFromAnalytics();
    removeToken();
    removeWebToken();

    yield put(resetState());
    yield put(resetAuth());
}

export const authTestData = {
    signIn,
    authenticate,
    authenticateBySignature,
    logout,
};

export default function* watchAuth() {
    yield all([
        takeLatest(LOG_OUT, logout),
        takeLatest(SIGN_IN_REQUEST, signIn),
        takeLatest(AUTHENTICATE, authenticate),
        takeLatest(AUTHENTICATE_BY_SIGNATURE, authenticateBySignature),
        takeLatest(WEB_TOKEN_REQUEST, getWebToken),
    ]);
}
