import { Text } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';
import React from 'react';

import useContactFormLink from 'hooks/common/useContactFormLink';

interface ContactSupportLinkProps {
    dataLocator?: string;
    onClick?: () => void;
}

const ContactSupportLink = ({ onClick, dataLocator }: ContactSupportLinkProps) => {
    const { t } = useTranslation();
    const contactFormLink = useContactFormLink();

    return (
        <a
            // href={`mailto:${base.supportEmail}`}
            href={contactFormLink}
            target="_blank"
            rel="noopener noreferrer"
            onClick={onClick}
            data-locator={dataLocator}
        >
            <Text type="overline" center bold text={t('basics.contactSupport')} mb={12} />
        </a>
    );
};

export default ContactSupportLink;
