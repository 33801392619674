import {
    deleteTokenOperation,
    deleteWebTokenOperation,
    setTokenOperation,
    setWebTokenOperation,
    setRefreshTokenOperation,
    deleteRefreshTokenOperation,
    setOneTimeTokenOperation,
} from 'store/auth/operations';
import { getAuthToken, getAuthRefreshToken, getTokenOneTime } from 'store/auth/accessors';

export const getToken = (): string | null => {
    return getAuthToken();
};

export const getOneTimeToken = (): string | null => {
    return getTokenOneTime();
};

export const removeToken = (): void => {
    deleteTokenOperation();
};

export const setToken = (token: string): void => {
    setTokenOperation(token);
};

export const setOneTimeToken = (oneTimeToken: string): void => {
    setOneTimeTokenOperation(oneTimeToken);
};

export const setWebToken = (webToken: string): void => {
    setWebTokenOperation(webToken);
};

export const removeWebToken = (): void => {
    deleteWebTokenOperation();
};

export const getRefreshToken = (): string | null => {
    return getAuthRefreshToken();
};

export const setRefreshToken = (refresh_token: string): void => {
    setRefreshTokenOperation(refresh_token);
};

export const removeRefreshToken = (): void => {
    deleteRefreshTokenOperation();
};
