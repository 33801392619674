import { useSelector } from 'react-redux';

import { getLanguage } from 'api/utils/languageManagment';

import { selectCurrentUser } from 'store/user/selectors';

import { isEmpty } from 'helpers/utils';

function useContactFormLink() {
    const language = getLanguage();
    const { email, name, id, country } = useSelector(selectCurrentUser);

    const baseUrl = 'https://contact-us.welltech.com/femia.html?payment_platform=web&source=web-cabinet';
    const queryParams = [];

    if (email) queryParams.push(`email=${encodeURIComponent(email)}`);
    if (name) queryParams.push(`name=${name}`);
    if (id) queryParams.push(`user_id=${id}`);
    if (country) queryParams.push(`locale=${language}-${country}`);

    const contactFormLink = isEmpty(queryParams) ? baseUrl : `${baseUrl}&${queryParams.join('&')}`;

    return contactFormLink;
}

export default useContactFormLink;
