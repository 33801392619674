import * as actionTypes from './actionTypes';

import { IAuthBySignature, IBaseAuth, ISignInRequest } from './types';

export const authentication = ({ onError, onSuccess, params }: IBaseAuth) => {
    return {
        type: actionTypes.AUTHENTICATE,
        payload: {
            onError,
            onSuccess,
            params,
        },
    } as const;
};

export const authenticationBySignature = ({ uId, signature, onError, onSuccess }: IAuthBySignature) => {
    return {
        type: actionTypes.AUTHENTICATE_BY_SIGNATURE,
        payload: {
            onSuccess,
            onError,
            uId,
            signature,
        },
    } as const;
};

export const setAuthenticationStatus = (isAuthenticated: boolean) => {
    return {
        type: actionTypes.SET_AUTHENTICATION_STATUS,
        payload: isAuthenticated,
    } as const;
};

export const setAuthRedirectUrl = (pathname: string | null) => {
    return {
        type: actionTypes.SET_AUTHENTICATION_REDIRECT_URL,
        payload: pathname,
    } as const;
};

export const signInRequest = (payload: ISignInRequest) => {
    return {
        type: actionTypes.SIGN_IN_REQUEST,
        payload,
    } as const;
};

export const signInSuccess = () => {
    return {
        type: actionTypes.SIGN_IN_SUCCESS,
    } as const;
};

export const signInError = (payload: Error) => {
    return {
        type: actionTypes.SIGN_IN_ERROR,
        payload,
    } as const;
};

export const logout = () => {
    return {
        type: actionTypes.LOG_OUT,
    } as const;
};

export const resetAuth = () => {
    return {
        type: actionTypes.RESET_AUTH,
    } as const;
};

export const setAuthToken = (payload: string | null) => {
    return {
        type: actionTypes.SET_AUTH_TOKEN,
        payload,
    } as const;
};

export const setTokenOneTime = (payload: string | null) => {
    return {
        type: actionTypes.SET_ONE_TIME_TOKEN,
        payload,
    } as const;
};

export const webTokenRequest = () => {
    return {
        type: actionTypes.WEB_TOKEN_REQUEST,
    } as const;
};

export const setAuthWebToken = (payload: string) => {
    return {
        type: actionTypes.SET_AUTH_WEB_TOKEN,
        payload,
    } as const;
};

export const setAuthRefreshToken = (payload: string | null) => {
    return {
        type: actionTypes.SET_AUTH_REFRESH_TOKEN,
        payload,
    } as const;
};
